import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ColorSwatchGrid from '../../../components/ColorSwatchGrid';
import ColorSwatch from '../../../components/ColorSwatch';
import { SprkLink, SprkIcon, SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Color Codes`}</h1>
    <h2 className="sprk-u-mbl">Rocket Red</h2>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
  <ColorSwatch variableName="sprk-red" colorName="Rocket Red" mdxType="ColorSwatch" />
  <ColorSwatch variableName="sprk-deep-red" mdxType="ColorSwatch" />
  <ColorSwatch variableName="sprk-mid-red" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h2 className="sprk-u-mbl">Orbit Purple</h2>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
  <ColorSwatch variableName="sprk-purple" colorName="Orbit Purple" mdxType="ColorSwatch" />
  <ColorSwatch variableName="sprk-purple-dark" mdxType="ColorSwatch" />
  <ColorSwatch variableName="sprk-purple-deep" mdxType="ColorSwatch" />
  <ColorSwatch variableName="sprk-purple-light" mdxType="ColorSwatch" />
  <ColorSwatch variableName="sprk-purple-lightest" hasOutline={true} mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h2 className="sprk-u-mbl">Neutral Colors</h2>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
  <ColorSwatch variableName="sprk-white" hasOutline={true} mdxType="ColorSwatch" />
  <ColorSwatch variableName="sprk-black" mdxType="ColorSwatch" />
  <ColorSwatch variableName="sprk-black-tint-80" mdxType="ColorSwatch" />
  <ColorSwatch variableName="sprk-black-tint-70" mdxType="ColorSwatch" />
  <ColorSwatch variableName="sprk-black-tint-60" mdxType="ColorSwatch" />
  <ColorSwatch variableName="sprk-black-tint-50" mdxType="ColorSwatch" />
  <ColorSwatch variableName="sprk-black-tint-40" mdxType="ColorSwatch" />
  <ColorSwatch variableName="sprk-black-tint-30" mdxType="ColorSwatch" />
  <ColorSwatch variableName="sprk-black-tint-20" hasOutline={true} mdxType="ColorSwatch" />
  <ColorSwatch variableName="sprk-black-tint-10" hasOutline={true} mdxType="ColorSwatch" />
  <ColorSwatch variableName="sprk-gray" hasOutline={true} mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h2 className="sprk-u-mbl">Semantic Colors</h2>
    <ColorSwatchGrid additionalClasses="sprk-u-mbl" mdxType="ColorSwatchGrid">
  <ColorSwatch variableName="sprk-green" mdxType="ColorSwatch" />
  <ColorSwatch variableName="sprk-blue" mdxType="ColorSwatch" />
  <ColorSwatch variableName="sprk-orange" additionalClasses="sprk-u-mbl" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <SprkLink element="a" href="/using-spark/foundations/color-accessibility" variant="has-icon" mdxType="SprkLink">
  Next: Color Accessibility
  <SprkIcon additionalClasses="
      sprk-u-mls
      sprk-c-Icon--m
      sprk-c-Icon--stroke-current-color" iconName="arrow-right" mdxType="SprkIcon" />
    </SprkLink>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      